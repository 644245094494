<template>
  <v-app id="talents-list" class="talents-list">
    <!--begin::Dashboard-->
    <div class="card card-custom card-stretch h-auto">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 align-items-center">
        <h3 class="card-title align-items-start flex-column m-0 py-3">
          <span class="card-label font-weight-bolder text-dark"
            >Talents List</span
          >
        </h3>
        <button
          v-if="Object.keys(query).length"
          class="btn btn-light font-weight-bolder text-dark-75 ms-auto"
          @click="exportToCSV"
        >
          <template v-if="!exportToCSVisLoading">
            <v-icon>mdi-file-document-outline</v-icon> Export to CSV
          </template>
          <template v-else>
            <v-icon>mdi-loading mdi-spin</v-icon> Exporting...
          </template>
        </button>
        <button
          class="btn btn-light font-weight-bolder text-dark-75 ms-2"
          v-b-toggle.filter-box
        >
          <v-icon>mdi-tune</v-icon> Filter
        </button>
      </div>
      <!--end::Header-->
      <!--begin::Body-->

      <TalentsListFilter @applyFilter="applyFilter" />
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <button
            class="btn btn-danger mb-3"
            v-if="selectedIds.length"
            @click="confirmDeleteSelectedTalents"
          >
            Delete Selected Talents
          </button>
          <!--begin::Table-->
          <div
            class="table-responsive mb-0"
            v-if="talents && talents.length && talents.length > 0"
          >
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr class="text-left">
                  <th class="pr-0 pl-3">Select</th>
                  <th>
                    <span class="text-dark-75 d-inline-flex align-items-center"
                      >SN
                      <i
                        class="flaticon2-information icon-md text-info ml-1"
                        v-b-tooltip="'Serial Number'"
                      ></i
                    ></span>
                  </th>
                  <th style="min-width: 185px">
                    <span>Name/Email</span>
                  </th>
                  <th>Register Date/Type</th>
                  <th>
                    <span style="display: inline-table; width: 150px">
                      <span> Full/Part Time Salary </span>
                      <i
                        class="flaticon2-information icon-md text-info ml-1"
                        v-b-tooltip="`Salary Doesn't include Squadio Margin`"
                      ></i>
                    </span>
                  </th>
                  <th style="min-width: 202px; max-width: 202px">
                    Role/Skills
                  </th>

                  <th class="text-center">Hiring Status</th>
                  <th class="text-center">Qualified</th>
                  <th class="text-center">Contacted</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="talent in talents"
                  :key="talent.id"
                  @click="openLinkInNewTab('/dashboard/talent/' + talent.id)"
                >
                  <td class="px-0" @click.stop>
                    <v-checkbox
                      v-model="selectedIds"
                      :value="talent.id"
                      hide-details
                      class="m-0"
                    ></v-checkbox>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{ talent.serial_number || "-" }}
                    </div>
                  </td>
                  <td class="pl-0" style="max-width: 185px">
                    <div class="d-flex align-items-center">
                      <div>
                        <div
                          class="text-dark-75 font-weight-bolder font-size-lg text-clamp-2"
                        >
                          {{ talent.first_name }} {{ talent.last_name }}
                        </div>
                        <div>
                          <span
                            class="text-muted font-size-sm"
                            style="word-break: break-all"
                          >
                            {{ talent.email }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="font-weight-bolder font-size-lg">{{
                      talent.register_date
                    }}</span>
                    <div>
                      <span
                        class="text-muted font-size-sm"
                        style="word-break: break-all"
                      >
                        {{ registerType(talent.registerType) }}
                      </span>
                    </div>
                  </td>
                  <td style="max-width: 120px">
                    <span class="font-weight-bolder font-size-lg">
                      {{ talent.salary ? "$" + talent.salary : "-" }}</span
                    >
                    /
                    <span class="font-weight-bolder font-size-lg">
                      {{
                        talent.part_time_salary
                          ? "$" + talent.part_time_salary
                          : "-"
                      }}</span
                    >
                  </td>
                  <td style="max-width: 202px">
                    <div
                      class="text-dark-75 font-weight-bolder font-size-lg"
                      v-if="talent.role"
                    >
                      <span v-if="talent.role.name">{{
                        talent.role.name
                      }}</span>
                    </div>
                    <div v-else>-</div>
                    <span class="text-muted" v-if="talent.skills.length"
                      >{{
                        talent.skills
                          .map((item) => item.name)
                          .slice(0, 3)
                          .toString()
                          .replaceAll(",", ", ")
                      }}
                      <span v-if="talent.skills.length > 3">...</span>
                    </span>
                    <div v-else>-</div>
                  </td>
                  <td
                    class="text-center text-center text-dark-75 font-weight-bolder font-size-lg"
                  >
                    {{
                      talent.hiring_status == 0 || talent.hiring_status == null
                        ? "APPLICANT"
                        : "Hired"
                    }}
                  </td>

                  <td
                    class="text-center text-dark-75 font-weight-bolder font-size-lg"
                  >
                    {{ talent.qualified ? "Yes" : "No" }}
                  </td>
                  <td class="pr-0 text-center" @click.stop>
                    <v-switch
                      hide-details
                      :input-value="talent.contacted"
                      :v-model="talent.contacted"
                      :loading="loading && activeContactedId === talent.id"
                      :disabled="loading"
                      @click="handleToggleContacted(talent.id)"
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5
            class="my-5 text-muted text-center"
            v-if="talents && talents.length === 0"
          >
            No Results Found
          </h5>
          <div class="pagination-container">
            <p
              v-if="talents && talents.length > 0"
              class="pagination-container__desc"
            >
              {{
                $t("showing_results", {
                  length: talents.length,
                  total: talentsMeta.total,
                })
              }}
            </p>
            <b-pagination
              v-if="talentsMeta && talentsMeta.last_page > 1"
              v-model="page"
              :total-rows="talentsMeta.total"
              :per-page="perPage"
              align="center"
              last-number
              first-number
            ></b-pagination>
          </div>

          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Dashboard-->
  </v-app>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import toasted from "@/core/plugins/vue-toasted";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TalentsListFilter from "@/components/talents-list/TalentsListFilter.vue";
export default {
  name: "TalentsList",
  components: { TalentsListFilter },
  data() {
    return {
      page: 1,
      perPage: 8,
      activeQualifiedId: null,
      activeContactedId: null,
      exportToCSVisLoading: false,
      query: {},
      selectedIds: [],
    };
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      talents: "talents/getTalentsData",
      talentsMeta: "talents/getTalentsMeta",
    }),
    talentsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
        ...this.query,
      };
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Talents" },
    ]);
  },
  watch: {
    page() {
      this.handleGettingTalentsData();
    },
  },
  created() {
    this.handleGettingTalentsData();
  },
  methods: {
    ...mapActions({
      getTalentsData: "talents/getTalentsData",
      toggleContacted: "talents/toggleContacted",
      deleteSelectedTalents: "talents/deleteSelectedTalents",
    }),
    registerAtDate(date) {
      const convertedDate = date.split("T")[0];

      return convertedDate;
    },
    registerType(registerType) {
      if (registerType == 0) {
        return "Other";
      } else if (registerType == 1) {
        return "SIGNUP";
      } else if (registerType == 2) {
        return "WORKABLE";
      } else if (registerType == 3) {
        return "VELENTS";
      }
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },
    handleGettingTalentsData() {
      this.getTalentsData(this.talentsQuery);
    },
    handleToggleContacted(id) {
      this.activeContactedId = id;
      this.$store.dispatch("talents/toggleContacted", id).finally(() => {
        this.activeContactedId = null;
      });
    },
    applyFilter(query) {
      this.query = query;
      this.page = 1;
      this.handleGettingTalentsData();
    },
    async exportToCSV() {
      try {
        this.exportToCSVisLoading = true;
        const response = await axios.get("/users/talents", {
          params: this.query,
          responseType: "blob",
          headers: {
            Accept: "text/csv",
          },
        });

        const date = new Date().toISOString().split("T")[0];
        const moduleName = "Talents"; // Change this to "clients" if needed
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/csv" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${moduleName}_${date}.csv`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.exportToCSVisLoading = false;
        console.log(error);
      } finally {
        this.exportToCSVisLoading = false;
      }
    },
    confirmDeleteSelectedTalents() {
      Swal.fire({
        title: "Are you sure",
        text: "You want to permanently delete the selected Talents accounts ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            users: this.selectedIds,
          };

          this.deleteSelectedTalents(payload).then(() => {
            // load updated talent list
            this.page = 1;
            this.handleGettingTalentsData();

            // reset selected Ids
            this.selectedIds = [];
            toasted.show("Selected Talents Deleted Successfully", {
              type: "success",
              icon: "check_circle",
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table.table-vertical-center {
  tr {
    &:hover {
      background: #f8f9fa;
    }
  }
  th {
    color: #3f4254 !important;
  }
  td {
    padding-top: 16px;
    vertical-align: top;
    &:not(:first-of-type, :last-of-type) {
      cursor: pointer;
    }
  }
}
::v-deep {
  .v-input--selection-controls .v-input__slot {
    justify-content: center;
  }
  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-input--selection-controls.v-input--checkbox {
    padding: 0;
  }
}
</style>
