<template>
  <b-collapse id="filter-box">
    <div class="card-header py-0 border-0">
      <div class="row">
        <div class="col-12 col-lg-6 mb-5">
          <v-text-field
            label="Search With Name or Email or SN"
            outlined
            dense
            hide-details
            v-model.trim="query.search"
          ></v-text-field>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="filteredIndustries"
            item-text="name"
            item-value="id"
            label="Industry"
            dense
            hide-details
            outlined
            v-model="query.industry"
          >
            <template v-slot:prepend-item>
              <div class="px-5 mb-4">
                <v-text-field
                  v-model.trim="industrySearch"
                  placeholder="Search"
                  hide-details
                  class="mt-0 pt-0 text-h6 w-100"
                ></v-text-field>
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-sm-6 col-lg-3 mb-5">
          <v-select
            :items="roles"
            label="Role"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            v-model="query.role"
            @change="loadRoleSkills"
            :menu-props="{ left: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-6 col-lg-3 mb-5">
          <v-select
            :items="filteredSkills"
            item-text="name"
            item-value="id"
            label="Skills"
            multiple
            dense
            hide-details
            outlined
            v-model="query.skills"
            :disabled="isLoadingSkills"
            :loading="isLoadingSkills"
            :menu-props="{ left: true, offsetY: true, maxWidth: 260 }"
          >
            <template v-slot:selection="{ index }">
              <!-- <v-chip v-if="index === 0" small>
                <span>{{ item.name }}</span>
              </v-chip> -->
              <span v-if="index === 0" class="grey--text text-caption">
                ({{ query.skills.length }} Skills)
              </span>
            </template>
            <template v-slot:prepend-item>
              <div class="px-5 mb-4">
                <v-text-field
                  v-model.trim="skillSearch"
                  placeholder="Search"
                  hide-details
                  class="mt-0 pt-0 text-h6"
                ></v-text-field>
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="contactOptions"
            item-text="text"
            item-value="value"
            label="Contacted"
            outlined
            dense
            hide-details
            v-model="query.contacted"
            :menu-props="{ left: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="qualifyOptions"
            item-text="text"
            item-value="value"
            label="Qualified"
            outlined
            dense
            hide-details
            v-model="query.qualified"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>

        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="signupSourceList"
            item-text="name"
            item-value="id"
            label="Registration Type"
            outlined
            multiple
            dense
            hide-details
            v-model="query.signupSource"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="signupCampaignList"
            label="Signup Campaign"
            outlined
            multiple
            dense
            hide-details
            v-model="query.signupCampaign"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="signupMediumList"
            label="Signup Medium"
            outlined
            multiple
            dense
            hide-details
            v-model="query.signupMedium"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="hiringStatusOptions"
            item-text="text"
            item-value="value"
            label="Hiring Status"
            outlined
            dense
            hide-details
            v-model="query.hiringStatus"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="percentageOptions"
            item-text="text"
            item-value="value"
            label="Profile Strength"
            outlined
            dense
            hide-details
            v-model="query.percentage"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-3 mb-5">
          <v-select
            :items="marketingCampaignList"
            item-text="name"
            item-value="id"
            label="Campaign"
            outlined
            dense
            hide-details
            v-model="query.marketingCampaign"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-sm-12 col-lg-6 mb-5">
          <v-select
            :items="jobTypeList"
            item-text="text"
            item-value="value"
            label="Job Type"
            outlined
            dense
            hide-details
            v-model="query.jobType"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="filteredCountries"
            item-text="name"
            item-value="id"
            label="Country"
            multiple
            dense
            hide-details
            outlined
            v-model="query.country"
            :menu-props="{ left: true, offsetY: true }"
          >
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex">
                    <div class="custom-item">
                      {{ item.name }}

                      <v-chip class="ml-2" small>
                        <span>{{ item.talents_count }}</span>
                      </v-chip>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ index }">
              <!-- <v-chip v-if="index === 0" small>
                <span>{{ item.name }}</span>
              </v-chip> -->
              <span v-if="index === 0" class="grey--text text-caption">
                ({{ query.country.length }} Countries)
              </span>
            </template>
            <template v-slot:prepend-item>
              <div class="px-5 mb-4">
                <v-text-field
                  v-model.trim="countrySearch"
                  placeholder="Search"
                  hide-details
                  class="mt-0 pt-0 text-h6"
                ></v-text-field>
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="filteredRegulationCountry"
            item-text="name"
            item-value="id"
            label="Regulation Country"
            multiple
            dense
            hide-details
            outlined
            v-model="query.regulationCountry"
            :menu-props="{ left: true, offsetY: true }"
          >
            <template v-slot:selection="{ index }">
              <!--   <v-chip v-if="index === 0" small>
                <span>{{ item.name }}</span>
              </v-chip> -->
              <span v-if="index === 0" class="grey--text text-caption">
                ({{ query.regulationCountry.length }} Countries)
              </span>
            </template>
            <template v-slot:prepend-item>
              <div class="px-5 mb-4">
                <v-text-field
                  v-model.trim="regulationCountrySearch"
                  placeholder="Search"
                  hide-details
                  class="mt-0 pt-0 text-h6"
                ></v-text-field>
              </div>
            </template>
          </v-select>
        </div>

        <div class="col-12 col-lg-6 mb-5">
          <v-select
            :items="seniorityLevel"
            label="Seniority Level"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            v-model="query.seniorityLevel"
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </div>

        <div class="col-12 col-lg-6 mb-5">
          <v-dialog
            ref="rangeMenuDialog"
            v-model="rangeMenu"
            :return-value.sync="query.range"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="query.range"
                icon="mdi-calendar"
                label="Range"
                readonly
                dense
                hide-details
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="query.range" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="rangeMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="dataRangeSorting"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <div class="col-6 col-lg-6 mb-5">
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-5 col"
            @click="applyFilter(true)"
          >
            Apply
          </button>
        </div>
        <div class="col-6 col-lg-6 mb-5">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-5 col"
            @click="resetQuery"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const initQuery = {
  search: "",
  contacted: null,
  qualified: null,
  signupSource: [],
  signupCampaign: [],
  signupMedium: [],
  role: "",
  skills: [],
  hiringStatus: null,
  industry: null,
  percentage: null,
  seniorityLevel: null,
  country: [],
  marketingCampaign: null,
  jobType: null,
  regulationCountry: [],
  range: [],
};
export default {
  name: "TalentsListFilter",
  created() {
    this.getRoles();
    this.loadCountries();
    this.getRegulationCountries();
    this.getMarketingCampaignList({ type: 1 });
    this.getIndustries();
    this.getSignupSourceList({ type: 1 });
    this.getSignupCampaignList();
    this.getSignupMediumList();
  },
  data() {
    return {
      rangeMenu: false,
      qualifyOptions: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
        {
          text: "Manually",
          value: 2,
        },
        {
          text: "HackerEarth",
          value: 3,
        },
        {
          text: "Velents",
          value: 4,
        },
      ],
      contactOptions: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      registrationOptions: [
        {
          text: "Signed Up",
          value: 1,
        },
        {
          text: "Workable Invitation",
          value: 2,
        },
        {
          text: "Velents",
          value: 3,
        },
      ],
      hiringStatusOptions: [
        {
          text: "APPLICANT",
          value: 0,
        },
        {
          text: "HIRED",
          value: 1,
        },
      ],
      percentageOptions: [
        {
          text: "0",
          value: 0,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "40",
          value: 40,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "60",
          value: 60,
        },
        {
          text: "70",
          value: 70,
        },
        {
          text: "80",
          value: 80,
        },
        {
          text: "90",
          value: 90,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      jobTypeList: [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
      ],
      seniorityLevel: [
        {
          text: "Junior (0-1 year of experience)",
          value: "junior",
        },
        {
          text: "Mid (2-3 years of experience)",
          value: "mid",
        },
        {
          text: "Senior (4+ years of experience)",
          value: "senior",
        },
      ],
      query: { ...initQuery },
      isLoadingSkills: false,
      skillSearch: null,
      countrySearch: null,
      regulationCountrySearch: null,
      industrySearch: null,
    };
  },
  watch: {
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.loadCountries();
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      roles: "talents/getRoles",
      skills: "talents/getRoleSkills",
      countries: "talents/getCountries",
      regulationCountries: "talents/getRegulationCountries",
      marketingCampaignList: "shared/getMarketingCampaignList",
      industries: "talents/getIndusters",
      signupSourceList: "shared/getSignupSourceList",
      signupCampaignList: "shared/getSignupCampaignList",
      signupMediumList: "shared/getSignupMediumList",
    }),
    filteredSkills() {
      return !this.skillSearch
        ? this.skills
        : this.skills.filter((skill) =>
            skill.name.toLowerCase().includes(this.skillSearch.toLowerCase())
          );
    },
    filteredCountries() {
      return !this.countrySearch
        ? this.countries
        : this.countries.filter((country) =>
            country.name
              .toLowerCase()
              .includes(this.countrySearch.toLowerCase())
          );
    },
    filteredRegulationCountry() {
      return !this.regulationCountrySearch
        ? this.regulationCountries
        : this.regulationCountries.filter((country) =>
            country.name
              .toLowerCase()
              .includes(this.regulationCountrySearch.toLowerCase())
          );
    },
    filteredIndustries() {
      return !this.industrySearch
        ? this.industries
        : this.industries.filter((skill) =>
            skill.name.toLowerCase().includes(this.industrySearch.toLowerCase())
          );
    },
  },
  methods: {
    ...mapActions({
      getRoles: "talents/getRoles",
      getRoleSkills: "talents/getRoleSkills",
      getAllSkills: "talents/getAllSkills",
      getCountries: "talents/getCountries",
      getRegulationCountries: "talents/getRegulationCountries",
      getMarketingCampaignList: "shared/getMarketingCampaignList",
      getIndustries: "talents/getIndustries",
      getSignupSourceList: "shared/getSignupSourceList",
      getSignupCampaignList: "shared/getSignupCampaignList",
      getSignupMediumList: "shared/getSignupMediumList",
    }),
    dataRangeSorting() {
      this.$refs.rangeMenuDialog.save(this.query.range);
      this.query.range = this.query.range.sort();
    },
    loadRoleSkills() {
      this.isLoadingSkills = true;
      this.skillSearch = "";
      this.query.skills = [];
      this.getRoleSkills(this.query.role).then(() => {
        this.isLoadingSkills = false;
      });
    },
    loadAllSkills() {
      this.isLoadingSkills = true;
      this.skillSearch = "";
      this.query.skills = [];
      this.getAllSkills().then(() => {
        this.isLoadingSkills = false;
      });
    },
    loadCountries() {
      let {
        search,
        qualified,
        contacted,
        signupSource,
        signupCampaign,
        signupMedium,
        industry,
        role,
        skills,
        hiringStatus,
        percentage,
        marketingCampaign,
        jobType,
        regulationCountry,
        seniorityLevel,
        range,
      } = this.query;
      if (
        !search &&
        qualified === null &&
        contacted === null &&
        !signupSource.length &&
        !signupCampaign.length &&
        !signupMedium.length &&
        industry === null &&
        hiringStatus === null &&
        percentage === null &&
        marketingCampaign === null &&
        jobType === null &&
        !regulationCountry.length &&
        !role &&
        !skills.length &&
        !seniorityLevel === null &&
        !range.length
      ) {
        this.getCountries();
        return;
      }
      let data = {};
      if (search) data.search = search;
      if (qualified !== null) data.qualified = qualified;
      if (contacted !== null) data.contacted = contacted;
      if (industry !== null) data.industry = industry;
      if (signupSource.length) data.signupSource = signupSource;
      if (signupCampaign.length) data.signupCampaign = signupCampaign;
      if (signupMedium.length) data.signupMedium = signupMedium;
      if (hiringStatus !== null) data.HiringStatus = hiringStatus;
      if (seniorityLevel !== null) data.seniority_level = seniorityLevel;
      if (percentage !== null) data.percentage = percentage;
      if (jobType !== null) data.jobType = jobType;
      if (marketingCampaign !== null) data.marketingCampaign = marketingCampaign;
      if (role) data.role = role;
      if (skills.length) data.skills = skills;
      if (regulationCountry.length) data.regulationCountry = regulationCountry;
      if (range.length) data.registerDate = [...range].sort();

      this.getCountries(data);
    },
    applyFilter(validateFirst) {
      let {
        search,
        qualified,
        contacted,
        signupSource,
        signupCampaign,
        signupMedium,
        industry,
        role,
        skills,
        hiringStatus,
        percentage,
        country,
        marketingCampaign,
        jobType,
        regulationCountry,
        seniorityLevel,
        range,
      } = this.query;
      if (
        validateFirst &&
        !search &&
        qualified === null &&
        contacted === null &&
        !signupSource.length &&
        !signupCampaign.length &&
        !signupMedium.length &&
        industry === null &&
        hiringStatus === null &&
        percentage === null &&
        marketingCampaign === null &&
        jobType === null &&
        seniorityLevel === null &&
        !country.length &&
        !regulationCountry.length &&
        !role &&
        !skills.length &&
        !range.length
      ) {
        this.$toasted.show("Please Add At Least One Filter", {
          type: "error",
          icon: "error",
        });
        return;
      }
      let data = {};
      if (search) data.search = search;
      if (qualified !== null) data.qualified = qualified;
      if (contacted !== null) data.contacted = contacted;
      if (industry !== null) data.industry = industry;
      if (signupSource.length) data.signupSource = signupSource;
      if (signupCampaign.length) data.signupCampaign = signupCampaign;
      if (signupMedium.length) data.signupMedium = signupMedium;
      if (seniorityLevel !== null) data.seniority_level = seniorityLevel;
      if (hiringStatus !== null) data.HiringStatus = hiringStatus;
      if (percentage !== null) data.percentage = percentage;
      if (jobType !== null) data.jobType = jobType;
      if (marketingCampaign !== null) data.marketingCampaign = marketingCampaign;
      if (role) data.role = role;
      if (skills.length) data.skills = skills;
      if (country.length) data.country = country;
      if (regulationCountry.length) data.regulationCountry = regulationCountry;
      if (range.length) data.registerDate = range;

      this.$emit("applyFilter", data);
    },
    async resetQuery() {
      this.query = { ...initQuery };
      this.countrySearch = null;
      this.regulationCountrySearch = null;
      await this.loadAllSkills();
      this.applyFilter();
    },
  },
  async mounted() {
    await this.loadAllSkills();
  },
};
</script>
